.primary_text {
  color: $primary-color-blue;
}

.description_text {
  color: $primary-color-blue;
}

.secondary_text {
  color: $primary-color-red;
}
.link_text {
  color: $primary-color-red;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
