$transition-property: all;
$transition-duration: 0.3s;
$transition-timing-function: ease;

.primary-btn {
  transition: $transition-property $transition-duration
    $transition-timing-function;
  border: $primary-color-blue 2px solid;
  background-color: $primary-color-blue !important;
  color: white !important;

  &:hover {
    border: $primary-color-blue 2px solid;
    background-color: $primary-color-blue-light !important;
    color: $primary-color-blue !important;
  }
}
.primary-btn-yellow {
  transition: $transition-property $transition-duration
    $transition-timing-function;
  border: $primary-color-yellow 2px solid;
  background-color: $primary-color-yellow !important;
  color: white !important;

  &:hover {
    border: $primary-color-yellow 2px solid;
    background-color: white !important;
    color: $primary-color-yellow !important;
  }
}

.primary-btn-red {
  transition: $transition-property $transition-duration
    $transition-timing-function;
  border: $primary-color-red 2px solid;
  background-color: $primary-color-red !important;
  color: white !important;

  &:hover {
    border: $primary-color-red 2px solid;
    background-color: transparent !important;
    color: $primary-color-red !important;
  }
}

.secondary-btn {
  transition: $transition-property $transition-duration
    $transition-timing-function;

  border: $primary-color-blue 2px solid;
  background-color: white !important;
  color: $primary-color-blue !important;

  &:hover {
    border: $primary-color-blue 2px solid;
    background-color: $primary-color-blue-light !important;
    color: $primary-color-blue !important;
  }
}
